.projects
{
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 13;
    transform: scale(0.8);
}

.dot-selection{
    transition: 0.2s ease-in-out;
    display:block;
    position: relative;
    
}

.dotCollection
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.projects h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 82px;

    color: #FFFFFF;
}

.projects p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    width: 486px;

    color: #FFFFFF;
    margin-top:50px;

}

.projectCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:70px;
    
}  

.projectCard div{
    margin-right: 4vw;
}
.projectCard img{
    margin-left: 4vw;
}

.projects-container
{
    z-index: 10;
    padding:20px;
    background: linear-gradient(180.01deg, rgba(217, 217, 217, 0.1786) -9.62%, rgba(61, 61, 61, 0.94) 111.45%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -25px 25px 105px -8px #000000;
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.projects-container img{
    width: 500px;
    object-fit: contain;
    border-radius: 18px;
    filter: drop-shadow(-22px 22px 20px rgba(0, 0, 0, 0.553));
    
}

.dot-selection.selected{
    transition: 0.3s ease-in-out;
}

.one{
    z-index: 11;
    transition: 0.3s ease-in-out;
    margin-right: -50px;
    padding:30px;
    filter: drop-shadow(-7px 3px 8px rgba(0, 0, 0, 0.39));
}

.two{
    z-index: 11;
    transition: 0.3s ease-in-out;
    margin-left: -50px;
    padding: 30px;
    filter: drop-shadow(-7px 3px 8px rgba(0, 0, 0, 0.39));
}

.two:hover{
    transition: 0.3s ease-in-out;
    translate: 10px;
    filter: drop-shadow(-7px 3px 8px rgba(0, 0, 0, 0.39)) brightness(130%);
}

.one:hover{
    transition: 0.3s ease-in-out;
    translate: -10px;
    filter: drop-shadow(-7px 3px 8px rgba(0, 0, 0, 0.39)) brightness(130%);
}

.viewButton
{
    transition: 0.2s ease-in-out;
    background-color: #5D737E;
    padding-top: 10px;
    padding-right: 28px;
    padding-bottom: 10px;
    padding-left: 28px;
    border-radius: 40px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
}

.viewButton:hover
{
    padding-left:35px;
    padding-right:35px;
    margin-left:-8px;
    margin-right:-8px;
    background-color: white;
    color: #5D737E;
    cursor: pointer;
}

.imgCont
{
    display: block;
    width: 600px;
}

.imgCont #three
{
    transition: 0.3s ease-in-out !important;
    z-index: 14;
    position: relative;
}

.imgCont #three:hover
{
    transform: scale(1.1);
}

.imgCont #two:hover
{
    transition: 0.3s ease-in-out !important;
    transform: scale(1.08);
    animation: none !important;
    cursor: pointer;
}
.imgCont #one:hover
{
    transition: 0.3s ease-in-out !important;
    transform: scale(1.08);
    animation: none !important;
    cursor: pointer;
}

.imgCont #two
{
    transition: 0.3s ease-in-out !important;
    position: absolute;
    width: 300px;
    z-index: 12;
    margin-top: 70px;
    margin-left: 400px;    
    animation: coolEffect1 4s infinite alternate;
}

.imgCont #one
{
    transition: 0.3s ease-in-out !important;
    position: absolute;
    width: 400px;
    z-index: 12;
    margin-top: -140px;
    margin-left: 170px;
    animation: coolEffect1 5s ease-in-out infinite alternate;
    animation-delay: 0.2s;
}

@keyframes coolEffect1 {
    to
    {
        transform: scale(1.08);
    }
}

@keyframes coolEffect2 {
    to
    {
        transform: translate(8px,-12px);
    }
}