/**----------------- Dark Mode ---------------**/

.backdrop-dark{
    width:100vw;
    height: auto;
    background-color: #02111B;
    overflow: hidden;
}

.circle{
    position:absolute;
    border-radius: 50%;
    background-color: #BEBEBE;
    
}

.bird {
    position:absolute;
}

.bird:nth-child(1){
    -webkit-animation: birdFlap 4s ease-in-out infinite alternate;
    -moz-animation: birdFlap 4s ease-in-out infinite alternate;
    animation: birdFlap 3s ease-in-out infinite alternate;
    animation-delay: 0.4s;
}
.bird:nth-child(2){
    -webkit-animation: birdFlap 5s ease-in-out infinite alternate;
    -moz-animation: birdFlap 5s ease-in-out infinite alternate;
    animation: birdFlap 4s ease-in-out infinite alternate;
    animation-delay: 0s;
}
.bird:nth-child(3){
    -webkit-animation: birdFlap 6s ease-in-out infinite alternate;
    -moz-animation: birdFlap 6s ease-in-out infinite alternate;
    animation: birdFlap 5s ease-in-out infinite alternate;
    animation-delay: 1s;
}
.bird:nth-child(4){
    -webkit-animation: birdFlap 4s ease-in-out infinite alternate;
    -moz-animation: birdFlap 4s ease-in-out infinite alternate;
    animation: birdFlap 3s ease-in-out infinite alternate;
    animation-delay: 3s;
}
.bird:nth-child(5){
    -webkit-animation: birdFlap 5s ease-in-out infinite alternate;
    -moz-animation: birdFlap 5s ease-in-out infinite alternate;
    animation: birdFlap 4s ease-in-out infinite alternate;
    animation-delay: 0.6s;
}
.bird:nth-child(6){
    -webkit-animation: birdFlap 6s ease-in-out infinite alternate;
    -moz-animation: birdFlap 6s ease-in-out infinite alternate;
    animation: birdFlap 5s ease-in-out infinite alternate;
    animation-delay: 2.2s;
}
.bird:nth-child(7){
    -webkit-animation: birdFlap 4s ease-in-out infinite alternate;
    -moz-animation: birdFlap 4s ease-in-out infinite alternate;
    animation: birdFlap 3s ease-in-out infinite alternate;
    animation-delay: 4s;
}
.bird:nth-child(8){
    -webkit-animation: birdFlap 5s ease-in-out infinite alternate;
    -moz-animation: birdFlap 5s ease-in-out infinite alternate;
    animation: birdFlap 4s ease-in-out infinite alternate;
    animation-delay: 1.5s;
}
.bird:nth-child(9){
    -webkit-animation: birdFlap 6s ease-in-out infinite alternate;
    -moz-animation: birdFlap 6s ease-in-out infinite alternate;
    animation: birdFlap 5s ease-in-out infinite alternate;
    animation-delay: 2.5s;
}
.bird:nth-child(10){
    -webkit-animation: birdFlap 5s ease-in-out infinite alternate;
    -moz-animation: birdFlap 5s ease-in-out infinite alternate;
    animation: birdFlap 4s ease-in-out infinite alternate;
    animation-delay: 3s;
}
.bird:nth-child(11){
    -webkit-animation: birdFlap 7s ease-in-out infinite alternate;
    -moz-animation: birdFlap 7s ease-in-out infinite alternate;
    animation: birdFlap 6s ease-in-out infinite alternate;
    animation-delay: 4s;
}

.circle:nth-child(1){
    -webkit-animation: starsGlow 4s ease-in-out infinite alternate;
    -moz-animation: starsGlow 4s ease-in-out infinite alternate;
    animation: starsGlow 4s ease-in-out infinite alternate;
    animation-delay: 0.4s;
}
.circle:nth-child(2){
    -webkit-animation: starsGlow 5s ease-in-out infinite alternate;
    -moz-animation: starsGlow 5s ease-in-out infinite alternate;
    animation: starsGlow 5s ease-in-out infinite alternate;
    animation-delay: 0s;
}
.circle:nth-child(3){
    -webkit-animation: starsGlow 6s ease-in-out infinite alternate;
    -moz-animation: starsGlow 6s ease-in-out infinite alternate;
    animation: starsGlow 6s ease-in-out infinite alternate;
    animation-delay: 1s;
}
.circle:nth-child(4){
    -webkit-animation: starsGlow 4s ease-in-out infinite alternate;
    -moz-animation: starsGlow 4s ease-in-out infinite alternate;
    animation: starsGlow 4s ease-in-out infinite alternate;
    animation-delay: 3s;
}
.circle:nth-child(5){
    -webkit-animation: starsGlow 5s ease-in-out infinite alternate;
    -moz-animation: starsGlow 5s ease-in-out infinite alternate;
    animation: starsGlow 5s ease-in-out infinite alternate;
    animation-delay: 0.6s;
}
.circle:nth-child(6){
    -webkit-animation: starsGlow 6s ease-in-out infinite alternate;
    -moz-animation: starsGlow 6s ease-in-out infinite alternate;
    animation: starsGlow 6s ease-in-out infinite alternate;
    animation-delay: 2.2s;
}
.circle:nth-child(7){
    -webkit-animation: starsGlow 4s ease-in-out infinite alternate;
    -moz-animation: starsGlow 4s ease-in-out infinite alternate;
    animation: starsGlow 4s ease-in-out infinite alternate;
    animation-delay: 4s;
}
.circle:nth-child(8){
    -webkit-animation: starsGlow 5s ease-in-out infinite alternate;
    -moz-animation: starsGlow 5s ease-in-out infinite alternate;
    animation: starsGlow 5s ease-in-out infinite alternate;
    animation-delay: 1.5s;
}
.circle:nth-child(9){
    -webkit-animation: starsGlow 6s ease-in-out infinite alternate;
    -moz-animation: starsGlow 6s ease-in-out infinite alternate;
    animation: starsGlow 6s ease-in-out infinite alternate;
    animation-delay: 2.5s;
}
.circle:nth-child(10){
    -webkit-animation: starsGlow 5s ease-in-out infinite alternate;
    -moz-animation: starsGlow 5s ease-in-out infinite alternate;
    animation: starsGlow 5s ease-in-out infinite alternate;
    animation-delay: 3s;
}
.circle:nth-child(11){
    -webkit-animation: starsGlow 7s ease-in-out infinite alternate;
    -moz-animation: starsGlow 7s ease-in-out infinite alternate;
    animation: starsGlow 7s ease-in-out infinite alternate;
    animation-delay: 4s;
}

@keyframes starsGlow{
    from{
        -webkit-box-shadow: 0px 0px 50px 5px #ffffff08; 
        box-shadow: 0px 0px 50px 5px #ffffff08;
    }
    to{
        -webkit-box-shadow: 0px 0px 50px 25px #ffffff25; 
        box-shadow: 0px 0px 50px 25px #ffffff25;
    }
}

@keyframes birdFlap{
    from{
        transform: translate(0px, 6px);
    }
    to{
        transform: translate(0px, -6px);
    }
}

.moon{
    position:absolute;
    overflow: hidden;
}

.moon-group:hover{
    cursor: pointer;
}

.moon-group.menu-animate:hover{
    cursor: default;
}

.moon.fog-menu-animate{
    animation-name: fog;
    animation-duration:1s; 
    animation-fill-mode: forwards;
    z-index: 14!important;
    cursor: pointer;
}
.moon-group .moon:nth-child(1){
    animation: breathingSun 2.5s ease-in-out infinite alternate;
}

.moon-group .moon:nth-child(2){
    animation: breathingSun 2.5s 0.2s ease-in-out infinite alternate;
}

.moon-group .moon:nth-child(3){
    animation: breathingSun 2.5s 0.4s ease-in-out infinite alternate;
}

.moon-group .moon:nth-child(4){
    animation: breathingSun 2.5s 0.6s ease-in-out infinite alternate;
}

.moon-group .menu-animate:nth-child(1){
    animation-name: openMenu;
    animation-duration:0.8s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
}

.moon-group .menu-animate:nth-child(2){
    animation-name: openMenu;
    animation-duration:0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    animation-iteration-count: 1;
}

.moon-group .menu-animate:nth-child(3){
    animation-name: openMenu;
    animation-duration:0.4s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
}

.moon-group .menu-animate:nth-child(4){
    animation-name: openMenu;
    animation-duration:0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.55s;
    animation-iteration-count: 1;
}



@keyframes openMenu {
    100% {
        width: 50vw;
        height: 300vh;
        right: -25vw;
        border-radius: 0%;
    }
}

@keyframes breathingSun {
    100% {
        scale: 1.1;
    }
}

@keyframes fog {
    90% {
        width: 600vw;
        height: 600vh;
        right: -300vw;
        top: -300vh;
        z-index: 20 !important;
    }
    100%{
        width: 600vw;
        height: 600vh;
        right: -300vw;
        top: -300vh;
        background: rgba(217, 217, 217, 0.36);
        z-index: 20 !important;
    }
}

.exitImg{
    display:none;
    position:absolute;
    z-index: 13;
    padding:10px;
    border-radius: 50%;
    top: 26px;
    right: 24px;
}

.exitImg.exitStyle:hover{
    transition: 0.4s ease-in-out;
    background-color:#3f40453f;
}

.exitImg.exitStyle {
    transition: 0.2s ease-in-out;
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.7s
}

.darkMode{
    position:absolute;
    width: 75px;
    height:36px;
    background-color: #fcfcfc;
    border-radius: 33px;
    display: none;
    z-index: 13;
    margin:20px;
    margin-left:30px;
    top: 12px;
    padding:5px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.darkMode:hover{
    cursor: pointer;
}

.darkMode .buttonCircle{
    width: 39px;
    height: 39px;
    position: relative;
    background-color: #7E7E7E;
    border-radius: 50%;
}

.backdrop-dark.darkMode{
    justify-content: flex-end !important;
}

.darkMode.exitStyle{
    transition: 0.2s ease-in-out;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

.buttonCircle img{
    user-select: none;
}

.menu{
    z-index: 30;
    display:none;
    flex-direction: column;
    position: absolute;
    width: 25vw;
    height:100vh;
    right: 0;
    top: 0;
    overflow: hidden;
}

.menu.exitStyle{
    display:block;
}


.menu-selector{
    display:none;
}

.options{
    display:flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 61px;
    margin:20px;
    color: #FFFFFF;
    margin-top:15vh;
}

.options a{
    transition: 0.1s ease-in-out;
    margin-top:3vh;
}

.options a:hover{
    transition: 0.2s ease-in-out;
    color: #3F4045;
}

.menu-selector.exitStyle:nth-child(1){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}
.menu-selector.exitStyle:nth-child(2){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}
.menu-selector.exitStyle:nth-child(3){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.2s
}
.menu-selector.exitStyle:nth-child(4){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}
.menu-selector.exitStyle:nth-child(5){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.4s
}
.menu-selector.exitStyle:nth-child(6){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

.menu .socials{
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:7vh;
}

.socials a{
    padding: 6px;
    margin-right:15px;
    margin-left:15px;
}

.socials a svg{
    width:35px;
    height:35px;
    transition: 0.1s ease-in-out;
    fill: #FFFFFF;
}

.socials a:hover svg{
    transition: 0.2s ease-in-out;
    fill: #3F4045;
}


.socials .menu-selector.exitStyle:nth-child(1){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}
.socials .menu-selector.exitStyle:nth-child(2){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1.9s
}
.socials .menu-selector.exitStyle:nth-child(3){
    display:block;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

@keyframes exitShow {
    100%{
        opacity: 1;
    }
}

a.exitStyle:hover{
    cursor:pointer;
}

/**----------------- Light Mode ---------------**/

.backdrop-light{
    width:100vw;
    overflow: hidden;
}

.sun{
    position:absolute;
    overflow: hidden;
}

.sun-group:hover{
    cursor: pointer;
}

.sun-group.menu-animate:hover{
    cursor: default;
}

.sunfog-menu-animate{
    animation-name: sunfog;
    animation-duration:1s; 
    animation-fill-mode: forwards;
    z-index: 14!important;
    cursor: pointer;
}

.sun-group .sun:nth-child(1){
    animation: breathingSun 2.5s ease-in-out infinite alternate;
}

.sun-group .sun:nth-child(2){
    animation: breathingSun 2.5s 0.2s ease-in-out infinite alternate;
}

.sun-group .sun:nth-child(3){
    animation: breathingSun 2.5s 0.4s ease-in-out infinite alternate;
}

.sun-group .sun:nth-child(4){
    animation: breathingSun 2.5s 0.6s ease-in-out infinite alternate;
}


.sun-group .menu-animate:nth-child(1){
    animation-name: openMenu;
    animation-duration:0.8s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
}

.sun-group .menu-animate:nth-child(2){
    animation-name: openMenu;
    animation-duration:0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    animation-iteration-count: 1;
}

.sun-group .menu-animate:nth-child(3){
    animation-name: openMenu;
    animation-duration:0.4s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
}

.sun-group .menu-animate:nth-child(4){
    animation-name: openMenu;
    animation-duration:0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.55s;
    animation-iteration-count: 1;
}



@keyframes sunfog {
    90% {
        width: 600vw;
        height: 600vh;
        right: -300vw;
        top: -300vh;
    }
    100%{
        width: 600vw;
        height: 600vh;
        right: -300vw;
        top: -300vh;
        background: rgba(244, 211, 94, 0.36);;
    }
}


.backdrop-light .exitImg.exitStyle:hover{
    transition: 0.4s ease-in-out;
    background-color:rgb(252, 213, 58);
}

.backdrop-light .socials a:hover svg{
    transition: 0.2s ease-in-out;
    fill: rgb(252, 213, 58);
}

.backdrop-light .options a:hover{
    transition: 0.2s ease-in-out;
    color: rgb(252, 213, 58);
}

.backdrop-light .socials a svg{
    transition: 0.2s ease-in-out;
    fill: #FAF0CA;
}

.backdrop-light .options a{
    transition: 0.2s ease-in-out;
    color: #FAF0CA;
}

.lightMode{
    position:absolute;
    width: 75px;
    height:36px;
    background-color: #fcfcfc;
    border-radius: 33px;
    display: none;
    z-index: 13;
    margin:20px;
    top: 12px;
    padding:5px;
    margin-left:30px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.lightMode:hover{
    cursor: pointer;
}

.lightMode .buttonCircle{
    width: 39px;
    height: 39px;
    position: relative;
    background-color: #F86E40;
    border-radius: 50%;
}

.lightMode .buttonCircle img{
    padding:4px;
    user-select:none;
}

.lightMode.exitStyle{
    transition: 0.2s ease-in-out;
    opacity: 0;
    animation-name: exitShow;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

.menu{
    position: fixed;
}

a{
    text-decoration: none;
    color: #FFFFFF;
}