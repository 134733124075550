@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@600;700&display=swap');

.home-section{
    position: relative;
    height: 100vh;
}

.name{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    user-select:none;
    margin-block-end: 0;
}

@keyframes moveEnglish {
    from
    {
        transform: translate(7vw);
    }
    to{
        transform: translate(0vw);
    }
}

@keyframes moveArabic {
    from
    {
        opacity: 0;
        transform: translate(-8vw);
    }
    to{
        opacity: 100;
        transform: translate(0vw);
    }
}

.english p{
    color: #FFFFFF;
    text-align: right;
    font-size: 85px;
    font-weight: 700;
    padding: 15px;
    line-height: 1;
    margin-block-end: -10px;
    margin-block-start: 5px;
    animation: moveEnglish 2s;
}

.typeArabic {
    opacity: 100;
    animation: moveArabic 2s;
}

.subTitle{
    opacity: 0;
    letter-spacing: 1.2px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
    animation: showSub 1.5s ease-in-out infinite alternate;
}

@keyframes showSub {
    70%{
        opacity: 100% ;
    }
    100%{
        opacity: 100%;
    }
}

.typeArabic{
    color: #3F4045;
    font-family: 'Noto Sans Arabic', sans-serif !important;
    line-height: 1;
    font-size: 90px;
    font-weight: 700;
    padding: 15px;
    margin-block-end: -10px;
    margin-block-start: 5px;
}

.textHolder{
    position: absolute;
    display:flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    user-select:none;
    flex-direction: column;
}

.sceneryDrag{
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}